<template>
  <div class="logo">
    <a href="#" class="logo__element">otoSELO</a>
  </div>
</template>

<script>
export default {
  name: 'LogoButtons'
}
</script>
<template>
  <LogoButton />
  <article class="content">
    <header>
      <section class="section">
        <h1 class="section__title">🤝 We create a user experience based on the deepest understanding of what design is and is not</h1>
        <p class="section__text">As a consultancy for startups and businesses, we build MVPs and proofs of concept, test theories, plan user journeys, model funnels and conduct A/B tests for e-commerce and media projects.</p>
        <p class="section__text">We provide solutions with good data.</p>
      </section>
    </header>

    <section class="section">
      <h2 class="section__sub-title">Design is more than just images. Design is an ongoing  process that centered around data and user experience for each specific case</h2>
      <p class="section__text">Flashy visualizations can raise eyebrows, but they don’t generate conversions, nor do they correspond to user behavior on different screens with user-specific settings and user-specific needs. They are static pictures, and that’s all they’ll be forever.</p>
    </section>

    <section class="section">
      <h2 class="section__sub-title">Understanding design</h2>
      <p class="section__text">Something beyond “just a browser”. Emotions, intentions, simple notifications and gamification are all elements that engage users and demonstrate the importance of real design in solving a user problem. User Experience is the environment in which the users live, not just HTML and CSS.</p>
    </section>

    <section class="section">
      <h2 class="section__sub-title">Technologies that matter the most for building interfaces and UX</h2>
      <div class="section__columns">
        <div class="section__semi">
          <h3 class="section__column-title">🔬</h3>
          <p>It all starts with research and defining goals, then comes terms and definitions.</p>
        </div>
        <div class="section__semi">
          <h3 class="section__column-title">⌨️</h3>
          <p>The framework of choice could be Vue, Svelte, or React. A lot of factors have to be weighed when determining what the best framework should be for a project, and sometimes the best answer is adopting an entirely new one. We take it all into consideration, from nature of a project to collecting data to maintenance.</p>
        </div>
      </div>
    </section>

    <section class="section">
      <h2 class="section__sub-title">Our expertise</h2>
      <p class="section__text">We develop and realize our own commercial and open-source projects, putting the best “how to” practices that we believe in into practice each and every day.</p>
    </section>

    <footer>
      <section class="section footer-section">
        <h2 class="section__sub-title">Contact<a class="footer-section__email-link" href="mailto:contact@otoSELO.com">@otoSELO.com</a></h2>
        <div>Copyright &copy; &infin; &ndash; {{year}}</div>
      </section>
    </footer>
  </article>
</template>

<script>
import LogoButton from './components/LogoButton.vue'

export default {
  name: 'App',
  components: {
    LogoButton,
  },

  setup() {
    const year = new Date().getFullYear()

    return { year }
  }
}
</script>
